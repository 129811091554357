<template>
  <div>
    <Nav/>
    <b-container class="text-left">
      <b-row class="my-2">
        <b-col><h1 class="d-inline-block">User Report:</h1></b-col>
        <b-col><h1 class="font-weight-bold text-right">Total number of users: {{ userTableDataItems.length }}</h1>
        </b-col>
      </b-row>

      <JsonCSV
          :name="csvDataFile"
          :labels="csvLabels"
          :fields="csvFields"
          :data="userTableDataItems">
        <b-btn>Download as CSV</b-btn>
      </JsonCSV>
      <b-table striped hover responsive align="center" id="user-report" :per-page="perPage"
               :fields="userTableDataFields"
               :items="userTableDataItems" :current-page="currentPage"></b-table>
      <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="user-report"
      ></b-pagination>

      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </b-container>
  </div>
</template>

<script>
import firebase from "firebase";
import {db} from '../../firebaseDatabase';
import {mapState, mapGetters, mapActions} from 'vuex';
import {languageObject} from "@/json/lang/lang";
import moment from 'moment';
import Nav from "@/views/admin/Nav";

import JsonCSV from 'vue-json-csv';


export default {
  components: {
    JsonCSV,
    Nav
  },
  data() {
    return {
      usersDBData: null,
      name: 'admin-user-report',
      perPage: 20,
      currentPage: 1,
      userTableDataFields: [
        {
          key: 'userId',
          label: 'User ID',
          sortable: true
        },
        {
          key: 'selectedAgency',
          label: 'Selected Agency',
          sortable: true
        },
        {
          key: 'otherAgency',
          label: 'Other Agency',
          sortable: true
        },
        {
          key: 'selectedRole',
          label: 'Selected Role',
          sortable: true
        },
        {
          key: 'otherRole',
          label: 'Other Role',
          sortable: true
        },
        {
          key: 'selectedCounty',
          label: 'Selected County',
          sortable: true
        },
        {
          key: 'otherCounty',
          label: 'Other County',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Registered on',
          sortable: true
        }
      ],
      userTableDataItems: [],
      csvDataFile: 'user-data.csv',
      csvLabels: {
        userId: 'User ID',
        selectedAgency: 'Selected Agency',
        otherAgency: 'Other Agency',
        selectedRole: 'Selected Role',
        otherRole: 'Other Role',
        selectedCounty: 'Selected County',
        otherCounty: 'Other County',
        createdAt: 'Registered on'
      },
      csvFields: [
        'userId',
        'selectedAgency',
        'otherAgency',
        'selectedRole',
        'otherRole',
        'selectedCounty',
        'otherCounty',
        'createdAt'
      ],
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    module() {
      return languageObject['en'].module;
    },

    rows() {
      return this.userTableDataItems.length
    }
  },
  created() {
    this.getData();

  },
  watch: {},
  mounted() {
  },
  methods: {
    ...mapActions([]),
    getData() {
      db.collection("userdetails").get()
          .then(querySnapshot => {
                let queryData = querySnapshot.docs.map(doc => {
                  return {
                    id: doc.id,
                    data: doc.data()
                  };
                })
                //console.log("queryData", queryData)
                this.usersDBData = queryData;
                this.parseUserDBData();
              }
          )
    },
    parseUserDBData() {
      this.usersDBData.forEach(userData => {
        const userId = userData.id;
        this.userTableDataItems.push({
          userId: userId,
          selectedAgency: userData.data.selectedAgency,
          otherAgency: userData.data.otherAgency,
          selectedRole: userData.data.selectedRole,
          otherRole: userData.data.otherRole,
          selectedCounty: userData.data.selectedCounty,
          otherCounty: userData.data.otherCounty,
          createdAt: moment(userData.data.createdAt.toDate()).format('DD-MM-YYYY')

        })
      })

    }

  }
}
;
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

h1 {
  font-size: 1.8em !important;
}
</style>
