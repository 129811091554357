import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import Modules from "../views/Modules.vue";
import UsersReport from "@/views/admin/UsersReport";
import Whitelist from "@/views/admin/Whitelist";
import Accessibility from "@/views/Accessibility";

import PublicIndex from "@/views/public/Index.vue"

import firebase from 'firebase/app'
import "firebase/auth";

import {db} from '@/firebaseDatabase';
import ModulesReport from "@/views/admin/ModulesReport";

Vue.use(VueRouter);


//Vue.js - How to handle multiple subdomains on a single app.
//https://medium.com/@apalshah/vue-js-how-to-handle-multiple-subdomains-on-a-single-app-cba9b1f916c4


/*

//Live sub domains
- Public site: training.somersetsurvivors.co.uk
- Restricted site: practitioners.somersetsurvivors.co.uk
//Dev sites
- Local:   localhost:8080 (restricted)
- Network: 192.168.1.126:8080 (public)
*/
const host = window.location.host
let restricted = true
//Dev
let publicHost = "training.somersetsurvivors.co.uk" //Live public site
if(process.env.NODE_ENV === "development") {
    publicHost = "192.168.1.126:8080" //Dev public site
    //publicHost = "scc001domestic.firebaseapp.com" //Dev remote public site
}

if(
    host === "192.168.1.126:8080" ||
    host === "training.somersetsurvivors.co.uk"||
    host === "scc001domestic.firebaseapp.com"
){
    restricted = false
}

console.log("host", host)
console.log("restricted", restricted)
console.log("process.env.NODE_ENV", process.env.NODE_ENV)
let routes = []

if(restricted) { //restricted site
    routes = [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/accessibility",
            name: "accessibility",
            component: Accessibility
        },
        {
            path: "/admin/user-report",
            name: "user-report",
            component: UsersReport,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: "/admin/module-report",
            name: "module-report",
            component: ModulesReport,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: "/admin/whitelist",
            name: "whitelist",
            component: Whitelist,
            meta: {
                requiresAuth: true,
                isAdmin: true
            }
        },
        {
            path: "/modules",
            name: "modules",
            component: Modules,
            meta: {requiresAuth: true}
        },
        {
            path: "/modules/:id",
            name: "modules",
            component: Modules,
            meta: {requiresAuth: true}
        },
        {
            path: "/login",
            name: "login",
            component: Login
        },
        {
            path: "/register",
            name: "register",
            component: Register
        }
    ];
} else {//public site
    routes = [
        {
            path: "/",
            name: "public-home",
            component: PublicIndex
        }
    ];
}


const router = new VueRouter({
    mode: "history",
    //base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = firebase.auth().currentUser;
    if (requiresAuth && !isAuthenticated) {
        next("/");
    } else if (to.matched.some(record => record.meta.isAdmin)) {
        let FBcurrentUserId = firebase.auth().currentUser.uid;
        //Does user have rights to visit the admin page?
        //Check if user is an admin
        db.collection("roles").doc(FBcurrentUserId).get()
            .then(querySnapshot => {
                if (querySnapshot.data()) {
                    if(querySnapshot.data().admin) {
                        //Allow access to admin page
                        next()
                    }
                    else{
                        //Not and admin so send home
                        next("/");
                    }
                }
            }).catch(
            error => {
                next("/");
            }
        );
    } else {
        next();
    }
});

export default router;
