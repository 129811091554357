<template>
  <b-modal class="help" centered  hide-footer hide-header id="help-modal" title="Help">
    <div class=" w-100 d-flex justify-content-end close-container">
      <b-button class="btn-reset" @click="$bvModal.hide('help-modal')">&times;</b-button>
    </div>
    <h1 class="mb-4 mb-md-3 mt-1">Help</h1>
    <div class="mt-2">If you experience any issues with using this site or would like to remove yourself from this course, please contact <a
        href="mailto:somersetsurvivors@desq.co.uk">somersetsurvivors@desq.co.uk</a> with a description of the problem.
      <br/><br/>
    </div>
  </b-modaL>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      user: {
        email: ''
      },
      errorMessage: ''
    };
  },
  methods: {

  }
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";

::v-deep  .modal-body {
  h1 {
    font-size: 1.8em !important;
  }

}

.close-container {
  height: 15px;
}


.btn {
  border: none;
  width: 500px;
}





@media only screen and (max-width: 767px) {

  //Typography
  .modal-body {
    h1 {
      font-size: 1.3em !important;
    }
  }
  .btn {
    border: none;
    width: 100%;
  }

}

</style>
