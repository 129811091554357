<template>
  <div id="app" class="h-100 d-flex flex-column">
    <!-- <header></header> -->
    <top-header v-if="$route.name !== 'public-home'"></top-header>
    <header-public v-else></header-public>
    <router-view class="flex-grow-1" id="main" role="main"/>
    <foot  v-if="$route.name !== 'public-home'"></foot>
    <foot-public  v-else></foot-public>

  </div>
</template>

<script>
import Header from "./components/Top-Header";
import Foot  from "./components/Foot";
import HeaderPublic from "./components/public/Top-Header";
import FootPublic  from "./components/public/Foot";
export default {
  components: {
    "top-header": Header,
    Foot,
    HeaderPublic,
    FootPublic
  },
  data() {
    return {

    };
  }
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
