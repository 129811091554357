import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import axios from "axios";
import { firestorePlugin } from 'vuefire';
import { firebaseApp } from './firebaseDatabase';
import VueCookies from 'vue-cookies';

//Bootstrap Vue tree shaking:
//https://bootstrap-vue.org/docs#tree-shaking-with-module-bundlers
import {FormSelectPlugin, LayoutPlugin, PaginationPlugin, TablePlugin} from 'bootstrap-vue';
import {FormCheckboxPlugin } from 'bootstrap-vue';
import { ModalPlugin } from 'bootstrap-vue';
import { ImagePlugin } from 'bootstrap-vue';
import { ButtonPlugin } from 'bootstrap-vue';
import { ButtonGroupPlugin } from 'bootstrap-vue';
import { InputGroupPlugin } from 'bootstrap-vue';
import { FormInputPlugin } from 'bootstrap-vue';
import { NavbarPlugin  } from 'bootstrap-vue';


Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(ImagePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(NavbarPlugin );
Vue.use(TablePlugin );
Vue.use(PaginationPlugin );
Vue.use(VueCookies);
Vue.use(firestorePlugin);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

//SCSS
import '@/assets/scss/main.scss';

let app;

firebaseApp.auth().onAuthStateChanged(user => {
  //console.log("user", user);
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});

