<template>
  <b-modal id="cookie-modal" ref="cookie-modal" class="" hide-footer hide-header no-close-on-backdrop
           title="CookieConsent">
    <div class="mt-2 w-100 text-center">
      <span class="mr-3">This website uses cookies to personalise content and analyse traffic.</span>
      <a class="mr-3" target="_blank" href="https://www.somerset.gov.uk/our-information/privacy-notices-and-cookies/">Learn
        more</a>
      <b-btn class="background-color6 font-weight-bold " @click="onAgreeToCookies">I agree</b-btn>
      <br/><br/>
    </div>
  </b-modaL>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      show: undefined
    };
  },
  mounted() {
    this.showModal();
  },
  computed: {},
  methods: {
    onAgreeToCookies() {
      this.$cookies.set("acceptcookies",true, Infinity);
      this.$refs["cookie-modal"].hide();
    },
    showModal() {

      if (!this.$cookies.get("acceptcookies")) {
        this.$refs['cookie-modal'].show();
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

::v-deep .modal-body {

  .btn {
    width: 10em;
    min-width: 10em;
    border: none;
  }
}

::v-deep .modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  position: fixed;
  bottom: 0;
}

::v-deep .modal-content {
  border: none;
  background-color: $color5;
  color: $color1;
  border-radius: 0 !important;

  a, a:link, a:hover, a:active, a:visited {
    color: $color1;
    text-decoration: underline;
  }
}
</style>
