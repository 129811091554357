<template>
  <div>
    <mast-head></mast-head>
    <b-container class="modules">
      <div class="pb-5 intro-text">
        <div>We have created a suite of modules dedicated to different aspects of this issue.</div>
        <div>You need to complete the first three modules first. Once you have completed the first three all the other
          modules
          will unlock.
        </div>
        <div>If you complete all the modules you will unlock the quiz, which will enable you to achieve a certificate
          for
          this learning.
        </div>
        <div>Each module will take approx 20 - 30 mins to complete.</div>
        <div v-show="firstModulesCompleteStatus" class="font-weight-bold">We would love to <a target="_blank"
                                                                                              href="https://forms.gle/Af4kyfDTCujVX4wb7">hear
          your feedback</a> - you can do this anytime and it will only take a couple of minutes.
        </div>
      </div>
      <div>
        <b-row>
          <b-col class="mb-4" md="4" v-for="(item, index) in module" :key="item.id">
            <div class="square"></div>
            <button
                :class="{disabled: !showThisModule(index), completed: isInDB(item.id),  even : getBGNum(index, item) !== 1 ,  odd : getBGNum(index, item) === 1  }"
                @click.stop="gotoCourse(item.dir)"
                class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1 background-transparent border-0 ">
              <span class="label text-left color1   position-absolute top-0 left-0">{{ item.title }}</span>
              <span class="label-number background-color1 text-left color0   position-absolute top-0 right-0  ">{{
                  index + 1
                }}</span>
            </button>
          </b-col>
        </b-row>
      </div>

    </b-container>

  </div>

</template>

<script>
import firebase from "firebase";
import {db} from '../firebaseDatabase';
import {mapState, mapGetters, mapActions} from 'vuex';
import {languageObject} from "@/json/lang/lang";

import MastHead from "@/components/MastHead";


export default {
  components: {
    MastHead

  },
  data() {
    return {
      firstModulesCompleteStatus: false,
      userData: null,
      modulesDBData: [],
      testDBData: null,
      name: '',
    };
  },
  computed: {
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_FB_USER_MODULE_DATA',
      'GET_LOGGED_IN_STATUS'
    ]),
    ...mapState([
      'FIREBASE_UID',
      'DEBUG_STATUS',
      'LOGGED_IN_STATUS'
    ]),
    module() {
      let modules = [];
      if (languageObject['en'].module) {
        modules = languageObject['en'].module;
      }
      return modules;
    },
    areAllModulesComplete() {
      let areAllModulesComplete = false;
      if (this.modulesDBData) {
        areAllModulesComplete = this.modulesDBData.length >= (this.module.length - 1)
      }
      return areAllModulesComplete;
    }
  },

  created() {
    if (this.GET_LOGGED_IN_STATUS) {
      this.getModuleData();
    }


  },
  watch: {
    GET_LOGGED_IN_STATUS() {
      this.getModuleData();
    },
    GET_FB_USER_MODULE_DATA() {
      this.modulesDBData = this.GET_FB_USER_MODULE_DATA;
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions([
      'ACTION_FB_ADD_USER_MODULE_DATA',
      'ACTION_FB_GET_USER_MODULE_DATA'
    ]),
    showThisModule(moduleIndex) {
      let bShowModule = false;
      if (moduleIndex === (this.module.length - 1)) {//If last module?
        // Then this is the quiz
        if (this.areAllModulesComplete) {//All the modules complete
          //Then Show quiz
          bShowModule = true;
        }
      } else {// Else this isn't the quiz
        if (moduleIndex < 3) {//Is this the first 3 modules
          //Then show
          bShowModule = true;
        } else {//Not in first 3 modules
          // Are 1st 3 complete?
          if (this.isInDB("module1") && this.isInDB("module2") && this.isInDB("module3")) {
            //Then Show
            bShowModule = true;
            this.firstModulesCompleteStatus = true;
          }
        }
      }

      return bShowModule;
    },
    getBGNum(index, item) {

      let bgNum = 2;
      if (index % 2 === 0) { //is Even
        bgNum = 1;
      }
      //if (this.isInDB(item.id)) {
      // bgNum = 0;
      // }
      return bgNum;
    },
    isInDB(moduleId) {
      let resultArray = [];
      if (this.modulesDBData) {
        resultArray = this.modulesDBData.filter(function (item) {
          return item["id"] === moduleId;
        });
      }
      return resultArray.length !== 0;
    }
    ,
    getDBId(moduleId) {
      let resultArray = [];
      if (this.modulesDBData) {
        resultArray = this.modulesDBData.filter(function (item) {
          return item["id"] === moduleId;
        });
      }
      return resultArray[0].id;

    }
    ,
    async getModuleData() {

      try {
        await this.ACTION_FB_GET_USER_MODULE_DATA();
      }
      catch (error) {
        console.error("Error getting module data: ", error);
      }
      finally {
        this.modulesDBData = this.GET_FB_USER_MODULE_DATA;
        if (this.$route.params.id) {
          let moduleInt = 0;
          moduleInt = parseInt(this.$route.params.id);
          this.visitModule("module" + moduleInt)
        }
      }
    }
    ,
    visitModule(moduleId) {
      //Add visit to the DB
      //check if this module id is already in the DB
      //If it isn't then add it
      if (!this.isInDB(moduleId)) {

        let data = {
          id: moduleId,
          createdAt: firebase.firestore.Timestamp.now()
        };

        this.ACTION_FB_ADD_USER_MODULE_DATA(data);

      } else {
        //console.log(moduleId + " Is already in the DB so don't add!");
      }


    },
    gotoCourse(dir) {
      //Redirect to the actual course
      window.location.href = '/practitioners/' + dir + '/index.html';
    }
  }
}
;
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.intro-text {
  font-size: calc(clamp(1rem, 1vw, 2rem));

  div {
    margin-bottom: .8rem;

    a {
      text-decoration: underline;
    }
  }
}

.active {
  color: white;
  background-color: $color3;
}

.module-btn {
  transition: background-image .8s ease-in;
  background-size: cover;
  background-position: center;


  &.completed {
    opacity: .5;
  }

  &.odd {
    background-image: url('~@/assets/img/background/module-bg1.png') !important;
  }

  &.even {
    background-image: url('~@/assets/img/background/module-bg2.png') !important;
  }

  &:hover {
    background-image: url('~@/assets/img/background/module-bg-hover.png') !important;
  }

  &.disabled:not(.completed) {
    background-image: url('~@/assets/img/background/module-bg0.png') !important;

    &:hover {
      background-image: url('~@/assets/img/background/module-bg0.png') !important;
    }
  }

  .label {
    margin: 15px 30px;
    font-size: 1.5em;
    width: 200px;
  }

  .label-number {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 22px;
    font-size: 1.5em;
    font-weight: bold;
    width: 45px;
    height: 45px;
    border-radius: 50%;

  }
}

.square {
  padding-bottom: 82%;
  position: relative;
  height: 0;
}

//Grid
.mb-4 {
  margin-bottom: 10px !important;
  padding-left: 5px;
  padding-right: 5px;

}


</style>
