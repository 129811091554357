import firebase from 'firebase/app'
import {db} from '@/firebaseDatabase';

export default {
    state: {
        LOGGED_IN_STATUS: false,
        FB_USER_DATA: null,
        FB_USER_MODULE_DATA: null
    },
    mutations: {
        MUTATION_SET_LOGGED_IN_STATUS: (state, payload) => {
            state.LOGGED_IN_STATUS = payload;
        },
        MUTATION_SET_FB_USER_DATA(state, payload) {
            state.FB_USER_DATA = payload;
            state.LOGGED_IN_STATUS = true;
        },
        MUTATION_SET_FB_USER_MODULE_DATA(state, payload) {
            if (payload) {
                // initialize a Set object
                const uniqueValuesSet = new Set();

                const payloadWithAnyDuplicatesRemoved = payload.filter((obj) => {
                    // check if name property value is already in the set
                    const isPresentInSet = uniqueValuesSet.has(obj.id);

                    // add name property value to Set
                    uniqueValuesSet.add(obj.id);

                    // return the negated value of
                    // isPresentInSet variable
                    return !isPresentInSet;
                });

                state.FB_USER_MODULE_DATA = payloadWithAnyDuplicatesRemoved;

            } else {
                state.FB_USER_MODULE_DATA = null;
            }


        }
    },
    actions: {

        async ACTION_FB_GET_USER_MODULE_DATA({commit, state}) {
            let modulesRef = db.collection("data").doc(state.FB_USER_DATA.id);
            const result = await modulesRef.get()
                .then(querySnapshot => {
                    if (querySnapshot.data()) {

                        commit('MUTATION_SET_FB_USER_MODULE_DATA', querySnapshot.data().modules);
                        return querySnapshot.data().modules;//return the modules visited data after response complete
                    }

                })
                .catch((error) => {
                    console.error("Error getting document: ", error);
                });

            return result;
        },
        ACTION_FB_ADD_USER_MODULE_DATA({commit, state}, payload) {
            let modulesRef = db.collection("data").doc(state.FB_USER_DATA.id);

            modulesRef.set({
                modules: firebase.firestore.FieldValue.arrayUnion(payload)
            }, {merge: true})
                .then(() => {
                    //Get latest data
                    //get any existing module data for  the user from FB
                    modulesRef.get()
                        .then(querySnapshot => {
                            if (querySnapshot.data()) {
                                commit('MUTATION_SET_FB_USER_MODULE_DATA', querySnapshot.data().modules);
                            }

                        })

                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
        },
        ACTION_FB_REGISTER_USER({commit, state}, payload) {
            commit('MUTATION_SET_LOADING', true)
            commit('MUTATION_CLEAR_ERROR');
            firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
                .then(
                    (userCreds) => {
                        commit('MUTATION_SET_FB_USER_DATA', {
                            id: userCreds.user.uid,
                            email: userCreds.user.email
                        });
                        //commit('MUTATION_SET_FB_USER_MODULE_DATA', []);
                        commit('MUTATION_SET_LOADING', false);
                        //Add user data
                        //console.log("userCreds.user", userCreds.user);
                        db.collection("userdetails").doc(userCreds.user.uid).set(
                            {
                                foreName: payload.forename,
                                lastName: payload.lastname,
                                selectedRole: payload.selectedRole,
                                otherRole: payload.otherRole,
                                selectedAgency: payload.selectedAgency,
                                otherAgency: payload.otherAgency,
                                selectedCounty: payload.selectedCounty,
                                otherCounty: payload.otherCounty,
                                createdAt: firebase.firestore.Timestamp.now()
                            }
                        )
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });

                    }
                )
                .catch(
                    error => {
                        commit('MUTATION_SET_LOADING', false)
                        commit('MUTATION_SET_ERROR', error)
                        console.log(error)
                    }
                )
        },
        ACTION_FB_SIGN_USER_IN({commit}, payload) {
            commit('MUTATION_SET_LOADING', true)
            commit('MUTATION_CLEAR_ERROR')
            firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
                .then(
                    data => {
                        commit('MUTATION_SET_LOADING', false)
                        const newUser = {
                            id: data.user.uid,
                            email: data.user.email
                        }
                        commit('MUTATION_SET_FB_USER_DATA', newUser)
                        //get any existing module data for  the user from FB

                        db.collection("data").doc(data.user.uid).get()
                            .then(querySnapshot => {
                                if (querySnapshot.data()) {
                                    //commit('MUTATION_SET_FB_USER_MODULE_DATA', querySnapshot.data().modules)
                                }


                                //console.log("querySnapshot", querySnapshot.data().modules);

                            });
                    }
                )
                .catch(
                    error => {
                        commit('MUTATION_SET_LOADING', false)
                        commit('MUTATION_SET_ERROR', error)
                        console.log(error)
                    }
                )
        },
        ACTION_FB_SIGN_USER_OUT({commit}) {
            firebase.auth().signOut()
                .then(() => {
                    commit('MUTATION_SET_FB_USER_DATA', null);
                    commit('MUTATION_SET_FB_USER_MODULE_DATA', null);
                    commit('MUTATION_SET_LOGGED_IN_STATUS', false);
                })
        },
        ACTION_FB_AUTO_SIGN_USER({commit}, payload) {
            commit('MUTATION_SET_FB_USER_DATA', {
                id: payload.uid,
                email: payload.email
            })
            //get any existing module data for  the user from FB
            db.collection("data").doc(payload.uid).get()
                .then(querySnapshot => {
                    if (querySnapshot.data()) {
                        //commit('MUTATION_SET_FB_USER_MODULE_DATA', querySnapshot.data().modules);
                        //console.log("querySnapshot", querySnapshot.data().modules);
                    }

                }).then(
                commit('MUTATION_SET_LOGGED_IN_STATUS', true)
            );
        }
    },
    getters: {
        GET_LOGGED_IN_STATUS(state) {
            return state.LOGGED_IN_STATUS;
        },
        GET_FB_USER_DATA(state) {
            return state.FB_USER_DATA;
        },
        GET_FB_USER_MODULE_DATA(state) {
            return state.FB_USER_MODULE_DATA;
        }
    }
}
