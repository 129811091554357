//En

import general from './en/general.json';
import module from './en/module.json';

let en = {
    general,
    module
}

/*import deContent from '@/json/lang/de/general.json';

let de = deContent;

import esContent from '@/json/lang/es/general.json';

let es = esContent;

import frContent from '@/json/lang/fr/general.json';

let fr = frContent*/


export const languageObject = {en}
