//import * as firebase from 'firebase';

import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA6kgW66NgNS4R8_mzDfGCxC6F1gDEjyx8",
    authDomain: "scc001domestic.firebaseapp.com",
    projectId: "scc001domestic",
    storageBucket: "scc001domestic.appspot.com",
    messagingSenderId: "428157380224",
    appId: "1:428157380224:web:745e339ec65714b94de865",
    measurementId: "G-VYH7B4E0D4"
}

export  const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
