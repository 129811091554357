<template>
  <div>
    <Nav/>
    <b-container class="text-left">
      <div class="my-2">
        <h1>Modules Completed Report:</h1>
      </div>
      <JsonCSV
          :name="csvDataFile"
          :labels="csvLabels"
          :fields="csvFields"
          :data="moduleTableDataItems">
        <b-btn>Download as CSV</b-btn>
      </JsonCSV>
      <b-table striped hover responsive align="center" id="module-report" :per-page="perPage"
               :fields="userTableDataFields"
               :items="moduleTableDataItems" :current-page="currentPage"></b-table>
      <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="module-report"
      ></b-pagination>

      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </b-container>
  </div>
</template>

<script>
import firebase from "firebase";
import {db} from '../../firebaseDatabase';
import {mapState, mapGetters, mapActions} from 'vuex';
import {languageObject} from "@/json/lang/lang";
import moment from 'moment';
import Nav from "@/views/admin/Nav";

import JsonCSV from 'vue-json-csv';


export default {
  components: {
    JsonCSV,
    Nav
  },
  data() {
    return {
      usersDBData: null,
      userDetailsDBData: null,
      name: 'admin-module-report',
      perPage: 20,
      currentPage: 1,

      userTableDataFields: [
        {
          key: 'userId',
          label: 'User ID',
          sortable: true
        },
        {
          key: 'selectedAgency',
          label: 'Selected Agency',
          sortable: true
        },
        {
          key: 'otherAgency',
          label: 'Other Agency',
          sortable: true
        },
        {
          key: 'moduleId',
          label: 'Module ID',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Completed on',
          sortable: true
        }
      ],
      moduleTableDataItems: [],
      userDetailsTableDataItems: [],
      csvDataFile: 'module-data.csv',
      csvLabels: {
        userId: 'User ID',
        selectedAgency: 'Selected Agency',
        otherAgency: 'Other Agency',
        moduleId: 'Module ID',
        createdAt: 'Completed on'
      },
      csvFields: [
        'userId',
        'selectedAgency',
        'otherAgency',
        'moduleId',
        'createdAt'
      ],
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    module() {
      return languageObject['en'].module;
    },

    rows() {
      return this.moduleTableDataItems.length
    }
  },
  created() {
    this.getData();

  },
  watch: {},
  mounted() {
  },
  methods: {
    ...mapActions([]),
    mergeUserDBDetailsAndModulesData(usersDBData, userDetailsDBData) {

      const mergeUserData = []

      usersDBData.forEach(module => {
            //Merge user data and module data on unique user IDs
            const matchedUserDBData = userDetailsDBData.find(user => user.id === module.id)
            if (matchedUserDBData) {
              module.data.modules.forEach((mdl, index) => {
                mergeUserData.push({
                  userId: module.id,
                  moduleId: module.data.modules[index].id,
                  selectedAgency: matchedUserDBData.data.selectedAgency,
                  otherAgency: matchedUserDBData.data.otherAgency,
                  createdAt: moment(module.data.modules[0].createdAt.toDate()).format('DD-MM-YYYY')
                })
              })

            }
          }
      )
      this.moduleTableDataItems = mergeUserData;
    },
    async getData() {

      let usersDBData = [];
      let userDetailsDBData = [];

      await db.collection("data").get()
          .then(querySnapshot => {
                let queryData = querySnapshot.docs.map(doc => {
                  return {id: doc.id, data: doc.data()};
                })
                this.usersDBData = queryData;
                usersDBData = queryData;
                //this.parseUserDBData();
              }
          )
      await db.collection("userdetails").get()
          .then(querySnapshot => {
                let queryData = querySnapshot.docs.map(doc => {
                  return {id: doc.id, data: doc.data()};
                })
                this.userDetailsDBData = queryData;
                userDetailsDBData = queryData
                //this.parseUserDetailsDBData();
              }
          )


      this.mergeUserDBDetailsAndModulesData(usersDBData, userDetailsDBData)


      //userDetailsDBData
    },
    parseUserDetailsDBData() {

      this.userDetailsDBData.forEach(userData => {
        const userId = userData.id;
        this.userDetailsTableDataItems.push({
          userId: userId,
          selectedAgency: userData.data.selectedAgency,
          otherAgency: userData.data.otherAgency
        })
      })
      // console.log("this.userDetailsDBData", this.userDetailsDBData)
    },
    parseUserDBData() {
      this.usersDBData.forEach(userData => {
        const userId = userData.id;
        const moduleDataArray = userData.data.modules;
        moduleDataArray.forEach((module) => {
          this.moduleTableDataItems.push({
            userId: userId,
            moduleId: module.id,
            createdAt: moment(module.createdAt.toDate()).format('DD-MM-YYYY')
          });
        })
      })

      //console.log("this.moduleTableDataItems", this.moduleTableDataItems)

    }

  }
}
;
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

h1 {
  font-size: 1.8em !important;
}
</style>
