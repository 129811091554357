<template>




    <nav class="background-color5  nav pl-2 pl-md-4  pl-lg-5  py-3" >
      <div class="pl-2 pl-md-4  pl-lg-5   h-100 d-flex align-items-center">
          <router-link class="color1" to="/">Home</router-link>
          <router-link class="color1 px-md-3"  to="/register" v-if="!GET_LOGGED_IN_STATUS">Register</router-link>
          <router-link class="color1"  to="/login" v-if="!GET_LOGGED_IN_STATUS">Login</router-link>
          <router-link class="color1 px-md-3"  to="/modules" v-if="GET_LOGGED_IN_STATUS">Modules</router-link>
          <a href="#" class="color1"  @click.stop="signOut" v-if="GET_LOGGED_IN_STATUS">Sign out</a>
        <a href="#" class="color1 pl-md-3" id="show-btn"@click.stop="$bvModal.show('help-modal')">Help</a>
      </div>
      <!-- Right aligned nav items -->
      <!-- Right aligned nav items -->
      <div class="ml-auto h-100">
        <img class="h-100 w-auto py-md-2 pr-2 pr-md-5 " :src="require('@/assets/img/logo.png'  )" alt="Logo"/>
      </div>
      <Help/>
    </nav>
</template>

<script>
import firebase from 'firebase'
import "firebase/auth";
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

import Help from '../views/Help';

export default {
  name: "top-header",
  components: {
    Help
  },
  data() {
    return {
      loggedIn: this.FB_USER_DATA !== null,
      authDetails: {"admin": false}
    };
  },
  created() {
    this.setupFirebase();
    //this.getAuthData();
  },
  mounted() {

  },
  watch: {},

  computed: {
    ...mapState([
      'LOGGED_IN_STATUS',
      'DEBUG_STATUS',
      'FB_USER_MODULE_DATA'
    ]),
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_LOGGED_IN_STATUS'
    ])
  },
  methods: {
    ...mapMutations(['SET_LOGGED_IN_STATUS']),
    ...mapActions([
      'ACTION_FB_SIGN_USER_OUT',
      'ACTION_FB_AUTO_SIGN_USER'
    ]),
    setupFirebase() {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          //this.SET_LOGGED_IN_STATUS(true);
          //LOGGED_IN_STATUS
          this.loggedIn = true;
          this.ACTION_FB_AUTO_SIGN_USER(user);
        } else {
          // No user is signed in.
          this.loggedIn = false;
          //this.SET_LOGGED_IN_STATUS(false);
          console.log("signed out", this.loggedIn);
        }
      });
    },
    signOut() {
      this.ACTION_FB_SIGN_USER_OUT();
      this.$router.replace({name: "login"})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.nav {
  height: 150px;
  font-size: 1.3em;
  font-weight: 600;
}
nav a.router-link-exact-active {
  color: $color3 !important;
}
div {
  color: inherit;
}

a, a:link, a:hover, a:visited, a:active {
  margin: 5px;
}
</style>
