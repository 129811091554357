<template>
  <div class="home">
    <mast-head></mast-head>
    <b-container>
      <b-row>

        <b-col>
          <div>
            <h2 class="color2 mb-5">Welcome to this online learning experience.</h2>
            <div class="text-left">
              Due to Covid the issue of domestic abuse has unfortunately become more prevalent, and the need to be able identify the signs of domestic abuse ever more important.
              <br/>
              <br/>These learning resources aim to address this, enabling more people from a wide range of agencies, such as teachers, police, social workers and health professionals, etc. to become more aware of the signs and more confident to know how to help and what to do.
              <br/>
              <br/>
              The Domestic Abuse Act signed into law in April 2021 further raises the need for more learning to be available on this topic.
            </div>
          </div>
        </b-col>

      </b-row>
      <b-row class="my-5" v-if="!GET_LOGGED_IN_STATUS">
        <b-col md="1"></b-col>
        <b-col>
          <b-btn md="4" class="background-color7 mb-1 p-3 font-weight-bold" @click="$router.push('login')">Login</b-btn>
        </b-col>
        <b-col>
          <b-btn md="4" class="background-color6 mb-1 p-3 font-weight-bold" @click="$router.push('register')">Register</b-btn>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
      <b-row class="my-5"  v-else>

        <b-col>
          <b-btn class="background-color6 p-3 font-weight-bold btn-module" @click="$router.push('modules')">Modules</b-btn>
        </b-col>

      </b-row>

    </b-container>

  </div>
</template>

<script>
import Login from "@/views/Login";
import MastHead from "@/components/MastHead";
import {mapState, mapGetters} from "vuex";

export default {
  name: "home",
  components: {
    Login,
    MastHead
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([
      'DEBUG_STATUS',
      'LOGGED_IN_STATUS'
    ]),
    ...mapGetters([
      'GET_LOGGED_IN_STATUS'
    ])
  },
  methods: {
  }
};
</script>
<style lang="scss">
.btn {
  border: none;
  width: 100%;
  min-width: 300px;
  font-size: 1.1em;
}
.btn-module {
  width: 500px;
}
@media only screen and (max-width: 768px) {
  .btn-module {
    width: 100%;
  }
}
</style>
