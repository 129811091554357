export default {
    state: {
        loading: false,
        error: null
    },
    mutations: {
        MUTATION_SET_LOADING (state, payload) {
            state.loading = payload
        },
        MUTATION_SET_ERROR (state, payload) {
            state.error = payload
        },
        MUTATION_CLEAR_ERROR (state) {
            state.error = null
        }
    },
    actions: {
        ACTION_CLEAR_ERROR ({commit}) {
            commit('MUTATION_CLEAR_ERROR')
        },
        ACTION_SET_ERROR ({commit}, payload) {
            commit('MUTATION_SET_ERROR', payload)
        }
    },
    getters: {
        GET_FB_LOADING_STATUS (state) {
            return state.loading
        },
        GET_FB_ERROR (state) {
            return state.error
        }
    }
}
