<template>
  <div>
    <mast-head></mast-head>
    <div>
      <h2 class="color2 mb-5">Please choose your language:</h2>
    </div>
    <div class="modules container mb-5">
      <b-row>
        <b-col class="mb-4" md="4">
          <div class="square"></div>
          <a href="./en/"
             class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1 background-transparent border-0 ">
            <span
                class="label text-left color1   position-absolute top-0 left-0">Domestic Abuse and Modern Slavery</span>
            <span
                class="lang label text-left color1   position-absolute left-0 mb-4">English</span>
          </a>
        </b-col>
        <b-col class="mb-4" md="4">
          <div class="square"></div>
          <a href="./pt/"
             class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1 background-transparent border-0 ">
            <span class="label text-left color1   position-absolute top-0 left-0">Violência doméstica e Escravatura moderna</span>
            <span
                class="lang label text-left color1   position-absolute left-0 mb-4">Português</span>
          </a>
        </b-col>
        <b-col class="mb-4" md="4">
          <div class="square"></div>
          <a href="./pl/"
             class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1 background-transparent border-0 ">
            <span class="label text-left color1   position-absolute top-0 left-0">Przemoc domowa i współczesne niewolnictwo</span>
            <span
                class="lang label text-left color1   position-absolute left-0 mb-4">Polski</span>
          </a>
        </b-col>
      </b-row>
    </div>

  </div>


</template>

<script>
import MastHead from "@/components/public/MastHead";

export default {
  name: "public-index",
  components: {
    MastHead
  }
}
</script>

<style lang="scss" scoped>

.module-btn {
  background-image: url('~@/assets/img/background/module-bg1.png') !important;
  transition: background-image .8s ease-in;
  background-size: cover;
  background-position: center;

  .lang {
    bottom: 15px;
    padding:0;
  }


  &.completed {
    opacity: .5;
  }


  &:hover {
    background-image: url('~@/assets/img/background/module-bg-hover.png') !important;
  }


  .label {
    margin: 15px 30px;
    font-size: 1.5em;
    width: 200px;
  }

  .label-number {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 22px;
    font-size: 1.5em;
    font-weight: bold;
    width: 45px;
    height: 45px;
    border-radius: 50%;

  }
}

.square {
  padding-bottom: 82%;
  position: relative;
  height: 0;
}

//Grid
.mb-4 {
  margin-bottom: 10px !important;
  padding-left: 5px;
  padding-right: 5px;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .module-btn {
    font-size: 0.875rem;
    .label {
      line-height: 1.9rem;
    }
  }
}
</style>