<template>
    <nav class="background-color5  nav pl-2 pl-md-4  pl-lg-5  py-3" >
      <div class="pl-2 pl-md-4  pl-lg-5   h-100 d-flex align-items-center">
      </div>
      <!-- Right aligned nav items -->
      <div class="ml-auto h-100">
        <img class="h-100 w-auto py-md-2 pr-2 pr-md-5 " :src="require('@/assets/img/logo.png'  )" alt="Logo"/>

      </div>
    </nav>
</template>

<script>

import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

export default {
  name: "top-header-public",
  components: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {

  },
  watch: {},

  computed: {
    ...mapState([

    ]),
    ...mapGetters([

    ])
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([

    ]),
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.nav {
  height: 150px;
  font-size: 1.3em;
  font-weight: 600;
}
nav a.router-link-exact-active {
  color: $color3 !important;
}
div {
  color: inherit;
}

a, a:link, a:hover, a:visited, a:active {
  margin: 5px;
}
</style>
