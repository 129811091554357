<template>
  <div class="banner text-left pl-3 pl-md-4  pl-lg-5 mb-5 d-flex flex-column justify-content-center "
       :style="{backgroundImage: 'url(' + require('@/assets/img/background/home.png') + ')'}">
    <div class="w-50 pl-2 pl-md-4  pl-lg-5 header">
      <h1 class="color1 pl-md-1">Domestic Abuse awareness</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: "masthead",
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss">
h1 {
  font-size: 4em;
  font-weight: bolder;
}

h2 {
  font-size: 2em;
}

.btn {
  min-width: 300px;
}
</style>
