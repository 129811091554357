import Vue from 'vue'
import Vuex from 'vuex'

import {vuexfireMutations, firestoreAction} from 'vuexfire'
import firebase from 'firebase/app'
import 'firebase/firestore'

import {db} from '../firebaseDatabase';

import user from '@/store/user';
import shared from '@/store/shared';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        DEBUG_STATUS: false,
    },
    mutations:
        {},
    getters: {},
    actions: {},
    modules: {
        user,
        shared
    }
})
