<template>
  <footer
      class="footer color1 background-color5 footer-height pl-2 pl-md-4  pl-lg-5  py-3 d-flex justify-content-center align-items-center">
<!--    <div>
      View our <a target="_blank" href="https://www.somerset.gov.uk/our-information/privacy-notices-and-cookies/">privacy
      policy</a> and
      <router-link to="/accessibility">accessibility statement</router-link>.
    </div>-->
    <div class="ml-auto h-100 py-md-2 pr-2 pr-md-5 text-right">
      <div><img class="py-2 footer-logo" :src="require('@/assets/img/logo.png'  )" alt="Logo"/></div>
<!--      <div><img class="py-2 footer-logo" src="https://practitioners.somersetsurvivors.co.uk/img/logo.png" alt="Logo"/></div>-->
      <div>
        <small>© Somerset Survivors 2021
          All rights reserved</small></div>
    </div>

    <CookieConsent></CookieConsent>
  </footer>
</template>
<script>

import CookieConsent from "@/components/CookieConsent";

export default {
  name: "foot-public",
  components: {
    CookieConsent
  }
}
</script>
<style lang="scss" scoped>
a, a:link, a:hover, a:active, a:visited {
  text-decoration: underline;
}

.footer-logo {
  height: 80px !important;
}
</style>
